export function redirectToApp(step, data) {
  if (!process.client) return;

  try {
    const appUrl = new URL(`citizenm://${step}`);
    for (const [item, val] of Object.entries(data)) {
      appUrl.searchParams.append(item, val);
    }
    window.location.href = appUrl.href;
    return;
  } catch (e) {
    console.log(e);
  }
}
